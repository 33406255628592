/* api.js */
import axios from 'axios'

// ATIS api
const deviceRequest = axios.create({
    baseURL: 'https://atis.ntpc.gov.tw/api/query'
});
const atisRequest = axios.create({
    baseURL: 'https://apiatis.ntpc.gov.tw/atis-api'
});
/* ATIS相關的 api */

//取得全部CMS訊息
export const apiAllCMSMsg = () => deviceRequest.get('/getCMSMsg');
//取得全部CMS
export const apiAllCMS = () => deviceRequest.get('/cfg/cms');
//取得即時公告資訊
export const apiAllAnnounce = () => atisRequest.get('/announce/query/number/100');