/* api.js */
import axios from 'axios'
import jsSHA from "jssha"

function getAuthorizationHeader() {
    const AppID = '8c655e1c78a749ecb6b903052fe26adf'
    const AppKey = '41c1Y3oQkpWW0PW9fiXxaD2Jhk4'

    const GMTString = new Date().toGMTString()
    const ShaObj = new jsSHA('SHA-1', 'TEXT')
    ShaObj.setHMACKey(AppKey, 'TEXT')
    ShaObj.update('x-date: ' + GMTString)
    const HMAC = ShaObj.getHMAC('B64')
    const Authorization = 'hmac username="' + AppID + '", algorithm="hmac-sha1", headers="x-date", signature="' + HMAC + '"'

    return {
        'Authorization': Authorization,
        'X-Date': GMTString /*,'Accept-Encoding': 'gzip'*/
    }; //如果要將js運行在伺服器，可額外加入 'Accept-Encoding': 'gzip'，要求壓縮以減少網路傳輸資料量
}
// PTX api
const ptxRequest = axios.create({
    baseURL: 'https://ptx.transportdata.tw',
});

/* PTX相關的 api */

//取得新北公車路線
export const apiAllBusRoute = () => ptxRequest.get('/MOTC/v2/Bus/Route/City/NewTaipei?$format=JSON', {
    headers: getAuthorizationHeader(),
});
//取得新北公車站
export const apiAllBusStation = () => ptxRequest.get('/MOTC/v2/Bus/Station/City/NewTaipei?$format=JSON', {
    headers: getAuthorizationHeader(),
});
//取得新北公車到站時間
export const apiAllEstimatedTime = () => ptxRequest.get('/MOTC/v2/Bus/EstimatedTimeOfArrival/City/NewTaipei?$format=JSON', {
    headers: getAuthorizationHeader(),
});
//取得ubike動態資訊
export const apiAllBikeAvailability = () => ptxRequest.get('/MOTC/v2/Bike/Availability/City/NewTaipei?&$format=JSON', {
    headers: getAuthorizationHeader(),
});