/* api.js */
import axios from 'axios'

// NTPC api
const ntpcRequest = axios.create({
    baseURL: 'https://apiatis.ntpc.gov.tw/ntpc-api'
});

/* NTPC相關的 api */

//取得全部施工資訊
export const apiAllConstruction = () => ntpcRequest.get('/Traffic/Construction/NewTaipeiCity');
//取得Ubike(站牌資訊)
export const apiAllUBikeStation = () => ntpcRequest.get('/Bike/Station/NewTaipeiCity');
//取得停車場資訊
export const apiAllParking = () => ntpcRequest.get('/Parking/Parkinglot/NewTaipeiCity');
//取得停車場動態車位數
export const apiAllAvailabilityParking = () => ntpcRequest.get('/Parking/Availability/NewTaipeiCity');