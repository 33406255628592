<template>
    <svg
        xmlns:dc="http://purl.org/dc/elements/1.1/"
        xmlns:cc="http://creativecommons.org/ns#"
        xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
        xmlns:svg="http://www.w3.org/2000/svg"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
        xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
        version="1.1"
        id="svg815"
        width="341.33334"
        height="341.33334"
        viewBox="0 0 341.33334 341.33334"
        sodipodi:docname="bus.svg"
        inkscape:version="0.92.4 (5da689c313, 2019-01-14)"
    >
        <metadata id="metadata821">
            <rdf:RDF>
                <cc:Work rdf:about="">
                    <dc:format>image/svg+xml</dc:format>
                    <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
                    <dc:title></dc:title>
                </cc:Work>
            </rdf:RDF>
        </metadata>
        <defs id="defs819" />
        <sodipodi:namedview
            pagecolor="#ffffff"
            bordercolor="#666666"
            borderopacity="1"
            objecttolerance="10"
            gridtolerance="10"
            guidetolerance="10"
            inkscape:pageopacity="0"
            inkscape:pageshadow="2"
            inkscape:window-width="2880"
            inkscape:window-height="1526"
            id="namedview817"
            showgrid="false"
            inkscape:zoom="3.7470702"
            inkscape:cx="170.66667"
            inkscape:cy="192.01669"
            inkscape:window-x="2869"
            inkscape:window-y="-11"
            inkscape:window-maximized="1"
            inkscape:current-layer="svg815"
        />
        <image
            width="341.33334"
            height="341.33334"
            preserveAspectRatio="none"
            xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQAAAAEACAYAAABccqhmAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAACdFJREFUeNrs3d1RW0kaBmDh4t5k
gDJAGaAMrAzQXrmKG5MBmghGvnEVVysiGByBDxGMiGBFBCMi0HSvm10NlrGEdH77eapOQTEefvp8
/Z6vW9LR0Wq16gF5emcIQAAAAgAQAIAAAAQAIAAAAQAIAEAAAAIAEACAAAAEACAAAAEACABAAAAC
ABAAgAAAqnVsCJrt6PKmHz701740CMfJi3+26Wsv//v7HX7sUzjmv/g3xa++tvrysXAGG15fbgte
y6Q+SZMyGqaP6xN91wnbBg/hWKbP5+nz5VrQzENgLFWHAOjalXuYrs7PV+kzo/Oqx3AsNhwCQgA0
fqIP0ucmeXnu10IhLjEWIRgWhkUAVNm6D9cm/Hsj05hgmD8fIRTmhkQAHGLCD9cOV/Z2hkLsFApL
CAGwzaSPV/VROkz4bnl4DgOBIABeTvpxmvSn5klWHcJdPHLdR8g2AFJ7Hyf9lUlP6g5muYVBdgGQ
du0n6WpvA4+fhcE0hcFSAHRr4l+ob7b0lLqCaVe7giwCIEz+OPGv1TN7uI0XkK4FQacDIG3uxQS3
m8+hOoJp6giWAqDZk3+cTpZ1PocWn6487sKLnd51ePL/2+SnJPFRo2+hzq50AM2b/HF3/w81SlV7
A6ETGAuAZkz++Nj+wpWfin0OIdDKbqBrS4CJyU8NPqXOUwdQ89X/L7VITeLG4KBtjw506ZZgdbdg
29xGi/LUfbOV01SDEx1APR3Aolfvc/rvQ/oPzcPazn8c+28NuAj029QFvOvQyfeCHuoW959atRfQ
lU3AkdrDUlQAQN3O0gvPBEBF7f9A+48uIN8OYKjeUJMCACwDBACoyywCIK3/PfWXJhoJgPIN1Bk6
AAEATfO+DfsAAgAy7gLaHgDnagwXqAwDIG0AggDYQ5tfDty09dV5CCXvs0arOtQ2LwF0AOhUBQDo
VHMMgL7awoUq3wDwbj8IgBwDoE2vt8YSQABo/8nXmQDIrK2CtnSsbX0ewEkJ3/M3pZq9Ya+cx+5j
ACwEQIM7gNWXjxP1n/2VelJSAMR6LSwBmt0BQHb1ag8AqllaCIADchcgyDEAPAeAFjoXAIcjACDj
ALABSOs09VWBbQwAG4C00YkA2D9Fh5YAtNQo1W+jHLdg0scJP+l9v8+63X/a6lM8Qj3Hz2/DMVt9
+VjoAF6f/HHi/yccFyY/HRLr+Vuo72k4TgTA5sk/Cx+u1Qod7wqKOkPgXYMn/4X6IAPx5cKFAPj/
5B+Z/OQWAmm5KwCCqXogQ9d1PMu1UQGQrv6naoFMjXPvAEZqgIyNcg8Az/Ij672A3APArb7JWtX7
AO8MOTSKAAAEACAAAAEACABAAAACABAAgAAABAAgAAABAAgAQAAAAgAQACAAAAEACABAAAACAKjY
MucAeHT+ydnqy8d5zgEwVwJkrPILYNMC4E4NkLE7AdDrPakDMjXNOgDC+idugEzUARn6HOp/kXsH
EEMgpuC9eiAjD3Vd+Jr6MOAoDQp0Xdz4G6XuVwCsLQWG4bhVH3RY7HQHdbT+z46bOjIpBMZHlzez
1B6dqxc61PJPQ43P6v5Fjps+UmGQitgNhCBYqRu6cNUPNT1syi/jqcCQMQEAAqDZQvt/4lRBvh3A
wKkCSwBAAAACABAA0CInAgDydSYAAAEACABAAAACABAAgAAASnRsCL47urwpjEL2+gKgmYYV/Ay3
HMMSABAAgAAABAAgAAABAAgAQAAAAgAQAIAAAAQAIAAAAQAIAEAAAAIAEACAAAAEACAAAAEAZB4A
C6cKBABgCQAIAEAAAAIAEACAAAAEAPBzx4ZgL4/huAtHEY7F6svHefzi0eVNP3yIxzAco3CcGaqD
enox7kUa95PwYZCOOPYfDNXrjlarVfN/ycubeDK/NehXug/H5Lnwtvz9J+E4V3J7T/yrOPnD2C+3
GPd+GveLJv0R4Xc/EgDtDICnNPGnb/w7YjcwC8d7c3lnt3HybzPxN4z7II37mQCwB7DP5B++dfKn
E3+X2tMHw7mTf4WxG79l8qdxn6clwa2hbGcALBsy+ecHSP9FKsZH5bf15J8dYNyXMUSEQAsD4BAT
b0+jQ/4O6Uo2SsHCz30+xOR/4UoHZgmwaxEWJYXaxPD+1GMYo6sSxj2G77jOv0sAtGvdX9okTfsJ
lgKbjUsc93mNS4GFAGiP2Vs3nnagC9h89S+MuwCoPQAq+Bl3hvkH07J/QNqMfRAAvHYVmldQiLHD
uDfc/1BU9HPuBABNWKvNDfcPa/QuBY0AcBV61dJw/4+H6ATARtrkPFQWhhVsNAoAaKr0GhMBwEYD
Q1ALr5gUANkFwNBw/+PK3BfyAqBupxUWoqtePYFoCaDWXjWu4Go3Nsy1jHu8e9AHAdAe8y4WYq/e
F6Y0dh+ggg26K8PcrgCo47HyuAyYlHgVGmn/f6rMce8LAEuAra8U6ZZSZbSgM8P7ahdQ1iSN4+62
bAJgK7FQZmnCHnLyF4rwl34/dPiG7zfVdQmAXcWbSRaHCIG1ye9W4dspDhUCaTn3yZC2MwCKBoTA
Yp/NqVTIJv/uHdif+ywHYuiGI77y79pw6gD2LcZvoZhmuzxHIBVgvPr8afLvtRwodg3g9DDrouch
v428M9DbxDeauAjF9bWX3qEm3WBivfBiQMQr/qjXsDemaLHzFMDxFYOzNO7zDUus53Ef95q3z1II
gO748HxlCYUXPzytdQqUuxz7fW3co3hvxVND090AaMNNM0z8+pj8Xd4DqODmnCAAAAHQVN5JBzIO
ADfPBEsAQAAAWQWARwLAHgAgAICsAqBwyiDTAPBGmpB3BxBNnDZa7E4A7NcFxGXArTqihW4rfOfj
znYAMQTGQoCW+ZzqtlGOVqtVa0c03e0lHm7ySBPF/arYsc5e3jBGANQXGkUVgRFO+JH6r+0cV1LU
XTjHngoMGRMAIAAAAQAIAEAAAAIAEACAAAAEACAAAAEACABAAEAOHgRAO1Xx3gKP5kfnJ+dSALRT
0ZGfQb3jfycA2mnWK/9dhmfmYK2mAkAAbJRuLV5mgXxNNy6lvnO8CB8+l/gjfmvqLb4EwHYFMumV
8/4Cce05NgUbYVLSXsBDqp+eAGi30YFD4L+TP3UYNKPTGx44BB7S9+yM7G4K+sMAXN7ENL/et+03
+Rt7fk963/dkPuz5reJtva86Nz65B0Aqkn5qGS92/F9jBzGx5m/FOR6mc7zrHaG/pnPcyTemFQA/
BsEotXmDcJy++Cfx0YNYCHEH+K4rG0GZnuN49Dec48d0josczrEAgIx5KjAIAEAAAAIAEACAAAAE
ACAAAAEACABAAAACABAAgAAABAAgAAABAAgAQAAAAgAQAIAAAKrytwADAHfO0KITd3psAAAAAElF
TkSuQmCC
"
            id="image823"
            x="0"
            y="0"
        />
    </svg>
</template>
<script>
export default { name: 'busIcon' };
</script>
