import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import CmsIcon from '@/components/svg/cmsSvg.vue'
import UbikeIcon from '@/components/svg/ubikeSvg.vue'
import ConstrIcon from '@/components/svg/constrSvg.vue'
import ParkingIcon from '@/components/svg/parkingSvg.vue'
import BusIcon from '@/components/svg/busSvg.vue'
Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        values: {
            cmsIcon: { // name of our custom icon
                component: CmsIcon, // our custom component
            },
            ubikeIcon: { // name of our custom icon
                component: UbikeIcon, // our custom component
            },
            constrIcon: { // name of our custom icon
                component: ConstrIcon, // our custom component
            },
            parkingIcon: { // name of our custom icon
                component: ParkingIcon, // our custom component
            },
            busIcon: { // name of our custom icon
                component: BusIcon, // our custom component
            },
        },
    },
});