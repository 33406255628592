<template>
    <svg
        xmlns:dc="http://purl.org/dc/elements/1.1/"
        xmlns:cc="http://creativecommons.org/ns#"
        xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
        xmlns:svg="http://www.w3.org/2000/svg"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
        xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
        version="1.1"
        id="svg1378"
        width="30"
        height="30"
        viewBox="0 0 30 30"
        sodipodi:docname="parking.svg"
        inkscape:version="0.92.4 (5da689c313, 2019-01-14)"
    >
        <metadata id="metadata1384">
            <rdf:RDF>
                <cc:Work rdf:about="">
                    <dc:format>image/svg+xml</dc:format>
                    <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
                    <dc:title></dc:title>
                </cc:Work>
            </rdf:RDF>
        </metadata>
        <defs id="defs1382" />
        <sodipodi:namedview
            pagecolor="#ffffff"
            bordercolor="#666666"
            borderopacity="1"
            objecttolerance="10"
            gridtolerance="10"
            guidetolerance="10"
            inkscape:pageopacity="0"
            inkscape:pageshadow="2"
            inkscape:window-width="896"
            inkscape:window-height="483"
            id="namedview1380"
            showgrid="false"
            inkscape:zoom="7.8666667"
            inkscape:cx="15"
            inkscape:cy="15"
            inkscape:window-x="0"
            inkscape:window-y="0"
            inkscape:window-maximized="0"
            inkscape:current-layer="svg1378"
        />
        <image
            width="30"
            height="30"
            preserveAspectRatio="none"
            xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABHNCSVQICAgIfAhkiAAAAYpJREFU
SEvtl09OwkAUxr8Hutam7q0n0K2JRTyBZ/AE4M60JNSF1Z1wA47gDVCr8RjCnqa41ECfeVWTEpHp
YFtcOLvJvL5f3r/pNwQAD27YjBkNIliyL2oxY0BAx/bNLgmUgeuiYPP8EnBKgRsOAGyXCU4iD9yQ
y4R+sf7B37LOjBfpxEXlYIJFQF2nV9SpZnRt32xm6QOdCckCPrd908sCFpugFdXBcV9lnzs4gTuh
B0J7EVwb3PeizeordtNOa5fGXXqf2LzFAyJs/ATXBs9LJTN6Nd88SUPu3dENgY4LBYvztWllZ//K
kFswWap05xKxgCbrFePIM8algWXOAW7W/K1eOq2BM7oF0WFuqVaNiZw/nUXWpBo/59rVWcCqxhIf
2jVWgQMn7IDQUNnlBv4Ys2kbILmzlUsb/OhEezFNZxQLM1m6skkbnPUuVoX8p8Ezv8XSImZgTJwS
AsT1rA30uzlWFWvJc3WNl3Ss+myl4NIFPYDh6p4wUotPdShKsuinzFCk8sGF2XkHGDgWaff2PKkA
AAAASUVORK5CYII=
"
            id="image1386"
            x="0"
            y="0"
        />
    </svg>
</template>
<script>
export default { name: 'parkingIcon' };
</script>
