<template>
    <svg
        xmlns:dc="http://purl.org/dc/elements/1.1/"
        xmlns:cc="http://creativecommons.org/ns#"
        xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
        xmlns:svg="http://www.w3.org/2000/svg"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
        xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
        version="1.1"
        id="svg825"
        width="32"
        height="30"
        viewBox="0 0 32 30"
        sodipodi:docname="constr.svg"
        inkscape:version="0.92.4 (5da689c313, 2019-01-14)"
    >
        <metadata id="metadata831">
            <rdf:RDF>
                <cc:Work rdf:about="">
                    <dc:format>image/svg+xml</dc:format>
                    <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
                    <dc:title></dc:title>
                </cc:Work>
            </rdf:RDF>
        </metadata>
        <defs id="defs829" />
        <sodipodi:namedview
            pagecolor="#ffffff"
            bordercolor="#666666"
            borderopacity="1"
            objecttolerance="10"
            gridtolerance="10"
            guidetolerance="10"
            inkscape:pageopacity="0"
            inkscape:pageshadow="2"
            inkscape:window-width="1920"
            inkscape:window-height="986"
            id="namedview827"
            showgrid="false"
            inkscape:zoom="7.8666667"
            inkscape:cx="16"
            inkscape:cy="15"
            inkscape:window-x="-11"
            inkscape:window-y="-11"
            inkscape:window-maximized="1"
            inkscape:current-layer="svg825"
        />
        <image
            width="32"
            height="30"
            preserveAspectRatio="none"
            xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAeCAYAAABNChwpAAAABHNCSVQICAgIfAhkiAAAAgJJREFU
SEvNl70yBEEQx/+9F5MRSN0tHkBEKJBQRSpA7u54A57AfcgRSKkikchIvAC3TihSJXDxTau9u8WY
3ZmeqboqF+71x6//Pb09Swj8cbW8jyg6HrgzjqiVHIaEohAn3ovnUcKT5kv9ZWq8PvjGCwOoVS5B
tKElY9xRK1kZOwBXy2uIouvcRIwdaiXnPhDeCnA9fgSwWJCkQ81kfmwA1uqzrJ4qeCngqD5D8FJB
DKCNnUtjj7GUA9TiNxBmRrk7YL7Vx5BWAcyNnvWomUy6ONP/RQBG9cxX1HrZ/J2A65VTgHa+nwlV
kAHo1ac5jD7nnI8elJqldvfdpoQTgGuVBojqf4JIANJ39Bk1X3aDAbhankIUvQKYCAMAoNS0TQWr
AgXVS1swYrarUAhgqd4TAEAfC3SSPOe1ohggb+H8RBCegUyE4kWVC5C7bnV8Y865Hn/mnJUfr4J1
nQ9gr17yfjFtCta1AcD7s0vg0n1YFoeXUuvU7t78tjIB7Os2801fMltauii6sLZgaGycHQ1AtG4L
Agk3ZXp/1C4tOoCsev8x1DujqfANwLV4G4QzYe/9xvBvUKUOqN1taNuQzYVjYzHHMNB/oIDXZUMo
kdNstK6HAH70zthCg4GKQwDXW0wY0dNseF/4Fy0YQKSfW6QqnlWEmZf4I/uMc96IwjLIvb4AokXz
xPgHCoAAAAAASUVORK5CYII=
"
            id="image833"
            x="0"
            y="0"
        />
    </svg>
</template>
<script>
export default { name: 'constrIcon' };
</script>
