<template>
    <v-app>
        <v-app-bar app>
            <TopBar />
        </v-app-bar>
        <ToolBar />
        <div class="hidden-sm-and-down"><FontLink /></div>
        <div class="hidden-md-and-up"><MFontLink /></div>
        <v-main id="main" class="pt-2 pt-md-0">
            <v-container fluid dark> <router-view /></v-container>
        </v-main>
        <Cards />
        <v-footer dark padless>
            <v-card class="flex" flat tile>
                <v-card-text :class="`py-2 white--text text-center ${fontStyle}`">
                    <strong>
                        <a href="#B" id="B" title="下方功能區" accesskey="B" class="text-decoration-none pl-2 white--text mr-3" style="color: #000000">:::</a>

                        系統維護：
                        <a href="https://www.iisigroup.com/" class="pl-2 white--text" style="color: #000000" target="_blank">資拓宏宇國際股份有限公司</a></strong
                    >
                    <br />
                    <h6>版權所有 新北市政府 New Taipei City Advanced Traveler Information System</h6>
                </v-card-text>
            </v-card>
        </v-footer>
    </v-app>
</template>
<script>
// @ is an alias to /src
import TopBar from '@/components/TopBar.vue';
import ToolBar from '@/components/ToolBar.vue';
import Cards from '@/components/Cards.vue';
import FontLink from '@/components/FontLink.vue';
import MFontLink from '@/components/MFontLink.vue';
//引入api.js 可以確保你網站的來源都是同一個進入點
import { apiAllCMS, apiAllCMSMsg } from './api/aitsApi.js';
import { apiAllBusStation } from './api/ptxApi.js';
import { apiAllUBikeStation, apiAllParking } from './api/ntpcApi';
export default {
    name: 'App',
    components: {
        TopBar,
        ToolBar,
        Cards,
        FontLink,
        MFontLink,
    },
    data() {
        return {
            fontStyle: '',
        };
    },
    methods: {
        async fetchAtisDataList() {
            try {
                const getBusStation = apiAllBusStation();
                const getAllCMS = apiAllCMS();
                const getAllCMSMsg = apiAllCMSMsg();
                const getAllUBikeStation = apiAllUBikeStation();
                const getAllParking = apiAllParking();
                this.setBusStationMapByStore(await getBusStation);
                this.setCmsMapByStore(await getAllCMS);
                this.setCmsMsgMapByStore(await getAllCMSMsg);
                this.setUbileStationMapByStore(await getAllUBikeStation);
                this.setParkingMapByStore(await getAllParking);
            } catch (err) {
                console.error(err);
            }
        },
        setCmsMapByStore(cms) {
            const _this = this;
            const cmsList = cms.data;
            cmsList.forEach(function(item) {
                _this.$store.commit('setCmsMap', item);
            });
        },
        setCmsMsgMapByStore(cmsMsg) {
            const _this = this;
            const msgList = cmsMsg.data;
            msgList.forEach(function(item) {
                _this.$store.commit('setCmsMsgMap', item);
            });
        },
        setBusStationMapByStore(station) {
            const _this = this;
            const stationList = station.data;
            stationList.forEach(function(item) {
                _this.$store.commit('setBusStationMap', item);
            });
        },
        setUbileStationMapByStore(station) {
            const _this = this;
            const stationList = station.data;
            stationList.forEach(function(item) {
                _this.$store.commit('setUbikeStationMap', item);
            });
        },
        setParkingMapByStore(parking) {
            const _this = this;
            const parkingList = parking.data;
            parkingList.forEach(function(item) {
                _this.$store.commit('setParkingMap', item);
            });
        },
        showPosition(position) {
            const pos = 'Latitude: ' + position.coords.latitude + '<br>Longitude: ' + position.coords.longitude;
            this.$store.commit('setLatitude', position.coords.latitude);
            this.$store.commit('setLongitude', position.coords.longitude);
            console.log('pos', pos);
        },
    },
    created() {
        console.log('APP Create');
        // created 階段就開始發出request
        this.fetchAtisDataList();
        if (navigator.geolocation) {
            navigator.geolocation.watchPosition(this.showPosition);
        } else {
            console.log('Geolocation is not supported by this browser.');
        }
    },
    watch: {
        '$store.state.fontStyle': function(val) {
            if (val) {
                this.fontStyle = val;
            }
        },
    },
};
</script>
<style>
a:focus,
input[type='text']:focus,
button:focus,
select:focus,
.page-link.active:focus,
.page-link:first-child:focus,
.page-link:last-child:focus {
    border: 2px dashed #da7b19;
    box-sizing: border-box;
}
.bigFont {
    font-size: 1.5em;
}
.bigFont .v-btn__content {
    font-size: 1.5em;
}
.smallFont {
    font-size: 0.875rem;
}
.smallFont .v-btn__content {
    font-size: 0.875rem;
}

.v-data-table.bigFont > .v-data-table__wrapper > table > thead > tr:last-child > th {
    font-size: 0.9em;
}
.v-data-table.bigFont > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
    font-size: 1.215rem;
}
.v-data-table.smallFont > .v-data-table__wrapper > table > thead > tr:last-child > th {
    font-size: 0.9em;
}
.v-data-table.smallFont > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
    font-size: 0.215rem;
}
.fontHidden {
    font-size: 0rem;
}
.fontHidden > .v-btn__content {
    font-size: 0rem;
}
.theme--light.v-breadcrumbs .v-breadcrumbs__divider,
.theme--light.v-breadcrumbs .v-breadcrumbs__item--disabled {
    color: rgba(0, 0, 0, 0.38);
}

.theme--dark.v-breadcrumbs .v-breadcrumbs__divider,
.theme--dark.v-breadcrumbs .v-breadcrumbs__item--disabled {
    color: rgba(255, 255, 255, 0.5);
}
</style>
