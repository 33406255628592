<template>
    <v-app-bar app color="#08202f" min-height="60">
        <a href="#main" tabindex="0" title="跳到主要內容" class="accessKeyU text-decoration-none">跳到主要內容</a>

        <h1>
            <a href="/accessibleWeb" title="新北市交通資訊友善服務網" class="d-flex align-center fontHidden">
                新北市交通資訊友善服務網
                <v-img alt="新北市交通資訊友善服務網" class="shrink mr-5" contain :src="require('../assets/ntpcLogo.png')" transition="scale-transition" max-width="200" />
            </a>
        </h1>
        <v-spacer></v-spacer>
        <!-- <v-breadcrumbs :items="items">
            <template v-slot:item="{ item }">
                <v-breadcrumbs-item :href="item.href" :disabled="item.disabled" :title="item.text" color="white--text">
                    <span class="white--text">{{ item.text.toUpperCase() }}</span>
                </v-breadcrumbs-item>
            </template>
        </v-breadcrumbs> -->
        <v-toolbar-items class="hidden-sm-and-down">
            <v-btn text color="white" light title="上方功能區" href="#U" id="U" accesskey="U">
                <h2 :class="fontStyle">:::</h2>
            </v-btn>
            <v-btn text color="white" light title="網路地圖" to="/siteMap">
                <h2 :class="fontStyle">網路地圖</h2>
            </v-btn>
            <v-btn text color="white" light title="關於我們" to="/aboutMe">
                <h2 :class="fontStyle">關於我們</h2>
            </v-btn>
        </v-toolbar-items>
        <v-btn icon class="hidden-md-and-up fontHidden" color="white accent-4 " @click.stop="drawer = !drawer"> switch<v-icon>mdi-dots-vertical</v-icon> </v-btn>
        <v-navigation-drawer v-model="drawer" absolute temporary app dark>
            <v-list dense>
                <v-list-item>
                    <v-list-item-avatar>
                        <v-img alt="新北市交通資訊友善服務網" contain :src="require('../assets/about.png')"></v-img>
                    </v-list-item-avatar>
                    新北市交通資訊友善服務網
                </v-list-item>
                <v-list-item-group color="orange" active-class="border" v-for="(item, i) in items" :key="i">
                    <v-list-item v-if="item.isMoble" :to="item.href">
                        <v-list-item-avatar rounded>
                            <v-icon dark>
                                {{ item.icon }}
                            </v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title v-text="item.text"></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider inset v-if="item.text === '關於我們'"></v-divider>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
    </v-app-bar>
</template>
<script>
export default {
    name: 'TopBar',
    data: () => ({
        drawer: false,
        fontStyle: '',
        items: [
            {
                text: ':::',
                isMoble: false,
                title: '上方功能區',
                href: '#/',
            },
            {
                text: '網路地圖',
                isMoble: true,
                title: '網路地圖',
                href: '/siteMap',
                icon: 'mdi-map-legend',
                color: 'amber',
            },
            {
                text: '關於我們',
                isMoble: true,
                title: '關於我們',
                href: '/aboutMe',
                icon: 'mdi-alert-circle',
                color: 'amber',
            },
            {
                text: 'CMS資訊',
                isMoble: true,
                icon: '$vuetify.icons.cmsIcon',
                href: '/cmsMsg',
                color: 'blue',
            },
            {
                text: 'YouBike',
                isMoble: true,
                icon: '$vuetify.icons.ubikeIcon',
                href: '/ubikeMsg',
                color: 'blue',
            },
            {
                text: '公車資訊',
                isMoble: true,
                icon: '$vuetify.icons.busIcon',
                href: '/busMsg',
                color: 'blue',
            },
            {
                text: '停車場',
                isMoble: true,
                icon: '$vuetify.icons.parkingIcon',
                href: '/parkingMsg',
                color: 'blue',
            },
            {
                text: '施工資訊',
                isMoble: true,
                icon: '$vuetify.icons.constrIcon',
                href: '/constrMsg',
                color: 'blue',
            },
            {
                text: '即時公告',
                isMoble: true,
                icon: 'mdi-clipboard-text',
                href: '/announceMsg',
                color: 'blue',
            },
        ],
    }),
    watch: {
        '$store.state.fontStyle': function(val) {
            if (val) {
                this.fontStyle = val;
            }
        },
    },
};
</script>
<style scoped>
.theme--light.v-breadcrumbs .v-breadcrumbs__divider,
.theme--light.v-breadcrumbs .v-breadcrumbs__item--disabled {
    color: #ffebee;
}
.accessKeyU {
    color: #08202f;
}
a:focus {
    border: 2px dashed #da7b19;
    color: #f8f8f8;
}
</style>
