import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'frontPage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '@/views/FrontPage.vue')
  },
  {
    path: '/busMsg',
    name: 'BusPage',
    component: () => import( /* webpackChunkName: "about" */ '@/views/BusPage.vue')
  },
  {
    path: '/cmsMsg',
    name: 'CmsPage',
    component: () => import( /* webpackChunkName: "about" */ '@/views/CmsPage.vue')
  },
  {
    path: '/constrMsg',
    name: 'ConstrPage',
    component: () => import( /* webpackChunkName: "about" */ '@/views/ConstrPage.vue')
  },
  {
    path: '/announceMsg',
    name: 'AnnouncePage',
    component: () => import( /* webpackChunkName: "about" */ '@/views/AnnouncePage.vue')
  },
  {
    path: '/aboutMe',
    name: 'AboutMePage',
    component: () => import( /* webpackChunkName: "about" */ '@/views/AboutMePage.vue')
  },
  {
    path: '/siteMap',
    name: 'SiteMapPage',
    component: () => import( /* webpackChunkName: "about" */ '@/views/SiteMapPage.vue')
  },
  {
    path: '/ubikeMsg',
    name: 'UbikePage',
    component: () => import( /* webpackChunkName: "about" */ '@/views/UbikePage.vue')
  },
  {
    path: '/parkingMsg',
    name: 'ParkingPage',
    component: () => import( /* webpackChunkName: "about" */ '@/views/ParkingPage.vue')
  },
  {
    path: '*',
    redirect: '/',
  }
]

const router = new VueRouter({
  // mode: 'history',
  routes
})

export default router