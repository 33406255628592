<template
    ><svg
        xmlns:dc="http://purl.org/dc/elements/1.1/"
        xmlns:cc="http://creativecommons.org/ns#"
        xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
        xmlns:svg="http://www.w3.org/2000/svg"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
        xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
        version="1.1"
        id="svg1931"
        width="36"
        height="28"
        viewBox="0 0 36 28"
        sodipodi:docname="ubike.svg"
        inkscape:version="0.92.4 (5da689c313, 2019-01-14)"
    >
        <metadata id="metadata1937">
            <rdf:RDF>
                <cc:Work rdf:about="">
                    <dc:format>image/svg+xml</dc:format>
                    <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
                    <dc:title></dc:title>
                </cc:Work>
            </rdf:RDF>
        </metadata>
        <defs id="defs1935" />
        <sodipodi:namedview
            pagecolor="#ffffff"
            bordercolor="#666666"
            borderopacity="1"
            objecttolerance="10"
            gridtolerance="10"
            guidetolerance="10"
            inkscape:pageopacity="0"
            inkscape:pageshadow="2"
            inkscape:window-width="1920"
            inkscape:window-height="986"
            id="namedview1933"
            showgrid="false"
            inkscape:zoom="8.4285714"
            inkscape:cx="18"
            inkscape:cy="14"
            inkscape:window-x="-11"
            inkscape:window-y="-11"
            inkscape:window-maximized="1"
            inkscape:current-layer="svg1931"
        />
        <image
            width="36"
            height="28"
            preserveAspectRatio="none"
            xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAcCAYAAAAJKR1YAAAABHNCSVQICAgIfAhkiAAAAp5JREFU
SEvtVj1oFFEQ/gZZC6212AWxFS3ExjQiKjZ7JGhnoyCCNhGRYHVWIYKFYKG1AW0CIkjktvCnsDOl
4pFCxEO4Bb3ewriMvHm7e+/tvv3THKTIVcfd7DfffPPNzBJ22Id2GB/sEmrqyH8rxJH/DaDDViLm
X0Bylno/NpoIFP+fDSHJwkMK42PbTsipQJ6FFyiMX5lJOfLnAVrXv/FjCuObXUg1KtSVkNAYBE9B
uKw5/Znr0rpGQlb1b/xD2ML7kmfqJGBsUG8811alboQi/y1A59qCG3H3KBzfbfOcRYiVAr9pBcSn
pirwBMAnAJsALUJNENHDqgQcBSsA+joOagKPpt9fAzhewuWkb7Y0JyRAzLdBtK++En5JYXzRFSOG
ZqxpDB3Hkf8ToAO1mIxn1BtfUTFCiKNgCcCDdDKGAK3C4+d0Pv6eVzydLCchUTf3Fw/hIcQWXRe1
jGfh4ZbgDvyrAN0A4aQ2vyZFuk3YNKsqjLH2jW5Vqp5z3FN/SYuvwcNHA3ciKimMvTiiCGU57IJ5
gTjyH4k3wCN4OO0MTn0D8AXtCXtybN9of1m4nFwC7VkT/zimjgfBB60Uv1OEPksSwJoEtx+mraVw
nLa77Bttg+yk6OVoL8xCLtU+oidKQeIo4NQ71tatX4hOS4tvMoVduPnCdLdOeGwTIe0b84zUF4r9
4GTeHPcsvrJlTg0MaYvmLMZXWaEJ1zT1hML4YO0VyMzX4hwYpm6PK6ZuGPvSeMq7DhapF6/Wkv9X
XMdiHIGxnCWsWmBt7lJh4dq4stWpX1qMhgJLYF6uPB2iDL3IVnwbQnmxHXCrjuuJdDepjTIC8BWF
I9iWkJDSZ+UOgDMG7hDAF3By33lcuySYZWyn96FZEsmwdwk1qfwX3bTLsRReFwwAAAAASUVORK5C
YII=
"
            id="image1939"
            x="0"
            y="0"
        />
    </svg>
</template>
<script>
export default { name: 'ubikeIcon' };
</script>
