<template>
    <svg
        xmlns:dc="http://purl.org/dc/elements/1.1/"
        xmlns:cc="http://creativecommons.org/ns#"
        xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
        xmlns:svg="http://www.w3.org/2000/svg"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
        xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
        version="1.1"
        id="svg4518"
        width="41"
        height="33"
        viewBox="0 0 41 33"
        sodipodi:docname="cmsIcon.svg"
        inkscape:version="0.92.4 (5da689c313, 2019-01-14)"
    >
        <metadata id="metadata4524">
            <rdf:RDF>
                <cc:Work rdf:about="">
                    <dc:format>image/svg+xml</dc:format>
                    <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
                    <dc:title></dc:title>
                </cc:Work>
            </rdf:RDF>
        </metadata>
        <defs id="defs4522" />
        <sodipodi:namedview
            pagecolor="#ffffff"
            bordercolor="#666666"
            borderopacity="1"
            objecttolerance="10"
            gridtolerance="10"
            guidetolerance="10"
            inkscape:pageopacity="0"
            inkscape:pageshadow="2"
            inkscape:window-width="2880"
            inkscape:window-height="1526"
            id="namedview4520"
            showgrid="false"
            inkscape:zoom="38.757576"
            inkscape:cx="20.5"
            inkscape:cy="9.2756059"
            inkscape:window-x="2869"
            inkscape:window-y="-11"
            inkscape:window-maximized="1"
            inkscape:current-layer="svg4518"
        />
        <image
            width="41"
            height="33"
            preserveAspectRatio="none"
            xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAAhCAYAAABEM4KbAAAABHNCSVQICAgIfAhkiAAAAu9JREFU
WEftmE1oE1EQx/+TCg21km1RVDQRRPAipXhVMB68qiDY9ORRBAV7LDbfFY8eFMSDB09NDh70Jh4a
QQ9etBUveiglET9QbIq1top5Mhsn3d28TbabpE3Bd2v3zcxv/jPz8nYJXbjOFyfOEgWSpGBUUBmj
LmRErBSfB+gAsylgpkshE8oqHsVK8WmAonZFFRQIkgFbtJZNa/4YsgDQCSuk3aUpeUuQ/vytRdUK
FCvZ5eYEcuGMbzHX68+53wVyrXGrzatm8+HssN8hsw6CF3+ukLHSRBSgpFI0TKSMqticg7NYflDZ
R70/pVAmUjOASufCkwXxrIWMlRIpAEk/4dtok86FM8yBOsiqgoHpNgZrwVXlJCuqgayf7haitGiq
nubC2Wgd5EgxsUAEw8371P507dHXP2Vc+XjT/PvevnH0URDPl1/j9rcHNvPLg+dwrG8I1v3R7Udx
ceBMbd+b1Tlc/3LfZsc9mo9kBjRK1h83bDkaOoXTO47bIDh4qKffdM6QvJYrKzVwiXhr7xh29hg1
SAG0gt3YfQmPl16g8OOlDZSPOs+QrKAuW/HIkHO/PuBI70HcXXhYCyZA878/oT8QNBOQhB99f4ap
xScNW8IzpJRr9L37wAvknm2DWKqsYPzzHTM4KyRLIAXcWn430o5AsprcFpIQV4AVGwoeqinJQNd2
XTBV56XrY4HuCKT056uf78w4h3sjZolZUVHSqpr0q9n3mkp5hpTyNMpYys2QrBKXndfb1aI57W6Q
vKeRfy3kSDFeJqKQs0ekPFZQ53RzqRnSeryIOlZIq53bycH/V0ot5iNZY12HuQyQJMATKwNiVZKf
cxmdAyTldvpx70uXw3xL/CxyVl1/wZBS/ruqpZTCsK5HG57APh9yDxKBr2qpplc1nzE6ZubpZt6x
6B4d66Zb87bo0dsGbdO+LW5Q7CZhmrwtbjbk1uzJzVZNF/+/ku2qiv2rmpr1/emkXUA6P9Xvk5Qi
wKgodfUvJRneHweIrIUAAAAASUVORK5CYII=
"
            id="image4526"
            x="0"
            y="0"
        />
    </svg>
</template>
<script>
export default { name: 'cmsIcon' };
</script>
