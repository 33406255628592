<template>
    <v-card class="hidden-sm-and-down">
        <v-toolbar color="rgb(48, 125, 157)" dark flat>
            <template v-slot:extension>
                <v-tabs v-model="model" centered :slider-color="sliderColor">
                    <v-tab v-for="(item, index) in items" :key="index" :to="item.href" class="py-2 white--text text-center">
                        <v-icon color="white darken-1 ma-2" class="mr-2" large> {{ item.icon }} </v-icon>
                        <h3 :class="fontStyle">{{ item.name }}</h3>
                    </v-tab>
                </v-tabs>
            </template>
        </v-toolbar>
    </v-card>
</template>
<script>
export default {
    name: 'ToolBar',
    data() {
        return {
            fontStyle: '',
            items: [
                {
                    name: '首頁',
                    icon: '',
                    href: '/',
                },
                {
                    name: 'CMS資訊',
                    icon: '$vuetify.icons.cmsIcon',
                    href: '/cmsMsg',
                },
                {
                    name: 'YouBike',
                    icon: '$vuetify.icons.ubikeIcon',
                    href: '/ubikeMsg',
                },
                {
                    name: '公車資訊',
                    icon: '$vuetify.icons.busIcon',
                    href: '/busMsg',
                },
                {
                    name: '停車場',
                    icon: '$vuetify.icons.parkingIcon',
                    href: '/parkingMsg',
                },
                {
                    name: '施工資訊',
                    icon: '$vuetify.icons.constrIcon',
                    href: '/constrMsg',
                },
                {
                    name: '即時公告',
                    icon: 'mdi-clipboard-text',
                    href: '/announceMsg',
                },
            ],
            model: '',
            sliderColor: 'yellow',
        };
    },
    watch: {
        '$store.state.fontStyle': function(val) {
            if (val) {
                this.fontStyle = val;
            }
        },
        '$route.path': function(path) {
            if (path === '/') {
                this.model = 0;
            }
            if (path.indexOf('aboutMe') !== -1 || path.indexOf('siteMap') !== -1) {
                this.sliderColor = 'rgb(48, 125, 157)';
            } else {
                this.sliderColor = 'yellow';
            }
        },
    },
};
</script>
