import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    cmsObj: new Map(), //CMS物件資訊
    cmsMsgObj: new Map(), //CMS訊息資訊
    busStationObj: new Map(), //公車站
    ubikeStationObj: new Map(), //ubike站
    parkingObj: new Map(), //停車場
    fontStyle: '', //網頁字體
    breadcrumbs: '', //
    latitude: '', //座標
    longitude: ''
  },
  mutations: {
    setCmsMap(state, payload) {
      state.cmsObj.set(payload.id, payload);
    },
    setCmsMsgMap(state, payload) {
      state.cmsMsgObj.set(payload.cmsId, payload);
    },
    setBusStationMap(state, payload) {
      state.busStationObj.set(payload.StationID, payload);
    },
    setUbikeStationMap(state, payload) {
      state.ubikeStationObj.set(payload.stationId, payload);
    },
    setParkingMap(state, payload) {
      state.parkingObj.set(payload.id, payload);
    },
    setFontSize(state, payload) {
      state.fontStyle = payload;
    },
    setBreadcrumbs(state, payload) {
      state.breadcrumbs = payload;
    },
    setLatitude(state, payload) {
      state.latitude = payload
    },
    setLongitude(state, payload) {
      state.longitude = payload
    }
  },
  actions: {},
  getters: {
    getCmsObjMap: state => {
      return state.cmsObj
    },
    getCmsMsgMap: state => {
      return state.cmsMsgObj
    },
    getBusStationMap: state => {
      return state.busStationObj
    },
    getBikeStationMap: state => {
      return state.ubikeStationObj
    },
    getParkingMap: state => {
      return state.parkingObj
    },
    getfontStyle: state => {
      return state.fontStyle
    },
    getLatitude: state => {
      return state.latitude
    },
    getLongitude: state => {
      return state.longitude
    },
  },
  modules: {}
})