import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueRouter from 'vue-router'
import routes from './router/routes' //路由規則
import store from './store'
import axios from 'axios'

Vue.config.productionTip = false

Vue.use(VueRouter)

new Vue({
  vuetify,
  router: routes,
  store,
  axios,
  render: h => h(App)
}).$mount('#app')

routes.replace('/');