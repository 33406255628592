<template>
    <div class="cards-BG">
        <v-container class="cards-BG">
            <v-row>
                <v-col cols="12" sm="6" md="4">
                    <v-card>
                        <v-img
                            alt="其他縣市交通資訊"
                            light
                            height="200px"
                            :src="require('../assets/title01.png')"
                            class="v-image-BgColor"
                        >
                            <v-app-bar flat color="rgba(0, 0, 0, 0)">
                                <v-toolbar-title :class="`white--text pl-0 ${fontStyle}`">
                                    其他縣市交通資訊
                                </v-toolbar-title>
                            </v-app-bar>
                        </v-img>

                        <v-card-text>
                            <v-timeline align-top dense>
                                <v-timeline-item
                                    v-for="message in otherCounties"
                                    :key="message.name"
                                    :color="message.color"
                                    small
                                >
                                    <div>
                                        <a
                                            :href="message.url"
                                            target="_blank"
                                            :title="message.name + '(另開新視窗)'"
                                            :class="`text-decoration-none ${fontStyle}`"
                                            >{{ message.name }}</a
                                        >
                                    </div>
                                </v-timeline-item>
                            </v-timeline>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-card>
                        <v-img
                            alt="大眾運輸資訊"
                            height="200px"
                            :src="require('../assets/title02.png')"
                            class="v-image-BgColor"
                        >
                            <v-app-bar flat color="rgba(0, 0, 0, 0)">
                                <v-toolbar-title :class="`white--text pl-0 ${fontStyle}`">
                                    大眾運輸資訊
                                </v-toolbar-title>

                                <v-spacer></v-spacer>
                            </v-app-bar>
                        </v-img>

                        <v-card-text>
                            <v-timeline align-top dense>
                                <v-timeline-item
                                    v-for="message in traffics"
                                    :key="message.name"
                                    :color="message.color"
                                    small
                                >
                                    <div>
                                        <a
                                            :href="message.url"
                                            target="_blank"
                                            :title="message.name + '(另開新視窗)'"
                                            :class="`text-decoration-none ${fontStyle}`"
                                            >{{ message.name }}</a
                                        >
                                    </div>
                                </v-timeline-item>
                            </v-timeline>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-card>
                        <v-img
                            alt="友善連結"
                            height="200px"
                            :src="require('../assets/title03.png')"
                            class="v-image-BgColor"
                        >
                            <v-app-bar flat color="rgba(0, 0, 0, 0)">
                                <v-toolbar-title :class="`white--text pl-0 ${fontStyle}`">
                                    友善連結
                                </v-toolbar-title>

                                <v-spacer></v-spacer>
                            </v-app-bar>
                        </v-img>

                        <v-card-text>
                            <v-timeline align-top dense>
                                <v-timeline-item
                                    v-for="message in friendlys"
                                    :key="message.name"
                                    :color="message.color"
                                    small
                                >
                                    <div>
                                        <a
                                            :href="message.url"
                                            target="_blank"
                                            :title="message.name + '(另開新視窗)'"
                                            :class="`text-decoration-none ${fontStyle}`"
                                            >{{ message.name }}</a
                                        >
                                    </div>
                                </v-timeline-item>
                            </v-timeline>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
export default {
    name: 'Cards',
    data: () => ({
        fontStyle: '',
        otherCounties: [
            {
                name: '基隆市交通資訊',
                url: 'http://e-traffic.klcg.gov.tw',
                color: 'deep-purple lighten-1',
            },
            {
                name: '高速公路路況資訊',
                url: 'http://1968.freeway.gov.tw/',
                color: 'green',
            },
            {
                name: '省道交通資訊',
                url: 'http://168.thb.gov.tw',
                color: 'deep-purple lighten-1',
            },
            {
                name: '臺北市交通資訊',
                url: 'http://its.taipei.gov.tw/',
                color: 'green',
            },
            {
                name: '桃園市交通資訊',
                url: 'https://tcc.tycg.gov.tw/ATISNew/',
                color: 'deep-purple lighten-1',
            },
            {
                name: '全國路況 e 網通',
                url: 'http://61.57.40.123/TrafficEvent.aspx',
                color: 'green',
            },
        ],
        traffics: [
            {
                name: '新北市公車動態',
                url: 'http://e-bus.tpc.gov.tw/',
                color: 'deep-purple lighten-1',
            },
            {
                name: '台鐵列車資訊',
                url: 'http://www.railway.gov.tw/',
                color: 'green',
            },
            {
                name: '交通服務 e 網通',
                url: 'https://traffic.transportdata.tw/',
                color: 'deep-purple lighten-1',
            },
            {
                name: '高鐵列車資訊',
                url: 'https://www.thsrc.com.tw/',
                color: 'green',
            },
        ],
        friendlys: [
            {
                name: '新北市全球資訊網',
                url: 'http://www.ntpc.gov.tw/',
                color: 'deep-purple lighten-1',
            },
            {
                name: '新北市警察局',
                url: 'https://www.police.ntpc.gov.tw/convenience-service.html',
                color: 'green',
            },
            {
                name: '氣象資訊',
                url: 'http://www.cwb.gov.tw/V7/forecast/town368/towns/6500001.htm?type=Weather&amp;time=7Day',
                color: 'deep-purple lighten-1',
            },
            {
                name: '路邊停車費查詢',
                url: 'http://parkweb.traffic.ntpc.gov.tw/',
                color: 'green',
            },
            {
                name: '新北市樂活地圖',
                url: 'http://map.tpc.gov.tw/',
                color: 'deep-purple lighten-1',
            },
            {
                name: '新北路邊停車空位查詢',
                url: 'https://tcdgis.ntpc.gov.tw/public/ParkingInformation.aspx',
                color: 'green',
            },
        ],
    }),
    watch: {
        '$store.state.fontStyle': function(val) {
            if (val) {
                this.fontStyle = val;
            }
        },
    },
};
</script>
<style scoped>
.v-image-BgColor {
    background-size: cover;
    background-color: #000;
}
.cards-BG {
    background-color: #08202f;
}
</style>
