<template>
    <div class="mx-10 pt-2 mt-16">
        <v-row>
            <v-col cols="6" sm="6" md="6">
                <v-breadcrumbs :items="breadcrumbs" class="pl-0 pt-0">
                    <template v-slot:divider>
                        <v-icon>mdi-chevron-right</v-icon>
                    </template>
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item v-if="item.text === 'home'">
                            <a
                                :title="item.title"
                                :href="item.href"
                                :disabled="item.disabled"
                                class="text-decoration-none fontHidden"
                            >
                                {{ item.title }}
                                <span><v-icon>mdi-home</v-icon></span>
                            </a>
                        </v-breadcrumbs-item>
                        <v-breadcrumbs-item v-if="item.disabled && item.text !== 'home'">
                            <span
                                ><v-icon color="primary">{{ item.icon }}</v-icon
                                >{{ item.text.toUpperCase() }}</span
                            >
                        </v-breadcrumbs-item>
                        <v-breadcrumbs-item v-if="!item.disabled && item.text !== 'home'">
                            <a
                                :title="item.title"
                                :href="item.href"
                                :disabled="item.disabled"
                                class="text-decoration-none fontHidden"
                                accesskey="C"
                            >
                                {{ item.title }}
                                <span>{{ item.text.toUpperCase() }} </span>
                            </a>
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </v-col>
            <v-col cols="6" sm="6" md="6">
                <a title="字型尺寸縮小" @click="changeFontSize('A-')">
                    <v-btn fab x-small :color="color.s" :class="`mx-1  ${fontStyle}`" :depressed="depressed.s">
                        A-
                    </v-btn>
                </a>
                <a title="字型尺寸為原始值" @click="changeFontSize('A')">
                    <v-btn fab x-small :color="color.g" :class="`mx-1  ${fontStyle}`" :depressed="depressed.g">
                        A
                    </v-btn>
                </a>
                <a title="字型尺寸放大" @click="changeFontSize('A+')">
                    <v-btn fab x-small :color="color.b" :class="`mx-1  ${fontStyle}`" :depressed="depressed.b">
                        A+
                    </v-btn>
                </a>
            </v-col>
        </v-row>
    </div>
</template>
<script>
export default {
    name: 'MFontLink', ////Link & font size(手機模式)
    data: () => ({
        fontStyle: '',
        depressed: {
            b: true,
            g: false,
            s: true,
        },
        color: {
            b: 'teal lighten-5',
            g: 'green',
            s: 'teal lighten-5',
        },
        breadcrumbs: [
            {
                text: ':::',
                disabled: false,
                href: '#main',
                title: '中央內容區，為本頁主要內容。',
            },
            {
                text: 'home',
                disabled: false,
                href: '#/',
                title: '首頁',
            },
        ],
    }),
    computed: {},
    methods: {
        changeFontSize(size) {
            let fontSize = 'fontSize';
            this.depressed = {
                b: true,
                g: true,
                s: true,
            };
            this.color = {
                b: 'teal lighten-5',
                g: 'teal lighten-5',
                s: 'teal lighten-5',
            };
            switch (size) {
                case 'A+':
                    fontSize = 'bigFont';
                    this.depressed.b = false;
                    this.color.b = 'green';
                    break;
                case 'A-':
                    fontSize = 'smallFont';
                    this.depressed.s = false;
                    this.color.s = 'green';
                    break;
                default:
                    fontSize = 'generalFont';
                    this.depressed.g = false;
                    this.color.g = 'green';
                    break;
            }
            this.$store.commit('setFontSize', fontSize);
        },
    },

    created() {
        this.$store.commit('setBreadcrumbs', this.breadcrumbs);
    },
    watch: {
        '$store.state.fontStyle': function(val) {
            if (val) {
                this.fontStyle = val;
            }
        },
        '$store.state.breadcrumbs': function(val) {
            if (val) {
                this.breadcrumbs = val;
            }
        },
    },
};
</script>
